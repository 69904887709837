import React, { useState, useEffect } from "react";
import { upperCase } from "lodash";
import Loader from "react-js-loader";
import {
	Box,
	Text
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { getOrgRoles } from "../App/useToken";
import { ReactComponent as Treasury } from '../../icons/treasury.svg';
import { callApiWithToken } from "../../utils/utils";

export default function TreasuryCard() {

	const [loading, setLoading] = useState(false);
	const [notifications, setNotifications] = useState(0);

	const orgRoles = getOrgRoles();

	const getWalletNotifications = async () => {
    setLoading(true);
		const response = await callApiWithToken("POST", `/api/treasury/walletList`, {});
		setNotifications(response.count);
    setLoading(false);
	}

	useEffect(() => {
		getWalletNotifications();
	}, []);

	const treasuryDashboards = [
		{ dashboard: "Orders", icon: Treasury, link: "/treasury-order", visible: orgRoles.includes("transfi_admin") },
		{ dashboard: "Wallets", icon: MdOutlineAccountBalanceWallet, link: "/approve-wallet", visible: orgRoles.includes("transfi_admin"), notifications: notifications },
	]

	const FinanceTreasuryDashboard = (data) => {
		const details = data?.data;
		return (
			<>
				{details?.visible && (
					<div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px', height: '130px' }} >
						<Link to={`${details.link}`}>
							<div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
								<div>
									<h3 style={{ fontSize: '20px', fontWeight: '600' }}>{upperCase(details.dashboard)}</h3>
								</div>

								<div style={{ position: 'relative', width: '70px', height: '70px', borderRadius: '50%', display: 'flex', justifyContent: 'center' }}>
									{/* Badge for notification */}
									{details.notifications > 0 && (
										<div style={{
											position: 'absolute',
											top: '-10px',
											right: '-10px',
											backgroundColor: 'red',
											color: 'white',
											borderRadius: '50%',
											width: '20px',
											height: '20px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontSize: '12px',
											fontWeight: 'bold',
										}}>
											{details.notifications}
										</div>
									)}
									<details.icon style={{ fontSize: '50px' }} />
								</div>
							</div>
						</Link>
					</div>
				)}
			</>
		);
	};

	const renderTransfiTreasuryDashboards = () => {
		const transfiDashboards = Object.keys(treasuryDashboards).sort();
		return <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
			{transfiDashboards.map(value => {
				return <div style={{ marginBottom: '40px' }}><FinanceTreasuryDashboard data={treasuryDashboards[value]} /></div>
			})}
		</div>
	}

	return !loading ? (
		<>
			<Box w="100%" p={4}>
				<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', padding: '0 10px', marginBottom: '5px' }}>
					<Text fontWeight={500}>Treasury Dashboards</Text>

				</div>
				<hr style={{ margin: '0 8px', marginBottom: '16px' }} />
				{renderTransfiTreasuryDashboards()}
			</Box>

		</>
	) : (
		<Loader
			type="spinner-cub"
			bgColor={"#FFFFFF"}
			title={"Loading..."}
			color={"cyan.400"}
			size={100}
		/>
	);
};
