import React, { useState, useContext, useRef, useEffect, useMemo, useCallback } from "react";
import Loader from "react-js-loader";
import { startCase } from "lodash";
import { Box, Button, useDisclosure, Text, useToast, Modal, ModalOverlay, ModalHeader, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.min.css";
import "ag-grid-community/styles/ag-theme-quartz.min.css";

import { callApiWithToken } from "../../utils/utils";
import { getUserEmail } from "../App/useToken";

export default function ApproveWallet() {
  const [loading, setLoading] = useState(false);
  const [selectedDeclineRow, setSelectedDeclineRow] = useState({});
  const [selectedApproveRow, setSelectedApproveRow] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [api, setApi] = useState(null);
  const userEmail = getUserEmail();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeclineOpen, onOpen: onDeclineOpen, onClose: onDeclineClose } = useDisclosure();

  const gridRef = useRef();
  const toast = useToast();

  const quickFilterText = '';

  const onGridReady = useCallback((params) => {
    gridRef.current = params.api;
    setApi(params);
    const datasource = {
      async getRows(params) {
        console.log(params.request)
        const { startRow, endRow, filterModel } = params.request;
        const page = Math.floor(startRow / (endRow - startRow));
        const limit = endRow - startRow;

        try {
          const responseData = await callApiWithToken("POST", `api/treasury/walletList`, { page, limit, filters: filterModel });
          const { data, total } = responseData;

          setTotalRows(total);

          params.success({
            rowData: data,
            rowCount: total,
          });
          setLoading(false);
        } catch (error) {
          console.error('Error fetching rows', error);
          params.fail();
        } finally {
          setLoading(false);
        }
      }
    };
    params.api.setServerSideDatasource(datasource);
  }, []);

  const defaultColDef = useMemo(() => ({
    filter: true,
    floatingFilter: true,
  }), []);

  const quickFilterParser = useCallback((quickFilter) => {
    let quickFilterParts = [];
    let lastSpaceIndex = -1;
    const isQuote = (index) => quickFilter[index] === '"';
    const getQuickFilterPart = (lastSpaceIndex, currentIndex) => {
      const startsWithQuote = isQuote(lastSpaceIndex + 1);
      const endsWithQuote = isQuote(currentIndex - 1);
      const startIndex =
        startsWithQuote && endsWithQuote
          ? lastSpaceIndex + 2
          : lastSpaceIndex + 1;
      const endIndex =
        startsWithQuote && endsWithQuote ? currentIndex - 1 : currentIndex;
      return quickFilter.slice(startIndex, endIndex);
    };
    for (let i = 0; i < quickFilter.length; i++) {
      const char = quickFilter[i];
      if (char === ' ') {
        if (!isQuote(lastSpaceIndex + 1) || isQuote(i - 1)) {
          quickFilterParts.push(getQuickFilterPart(lastSpaceIndex, i));
          lastSpaceIndex = i;
        }
      }
    }
    if (lastSpaceIndex !== quickFilter.length - 1) {
      quickFilterParts.push(
        getQuickFilterPart(lastSpaceIndex, quickFilter.length)
      );
    }
    return quickFilterParts;
  }, []);

  useEffect(() => {
    refreshGrid;
  }, []);

  const refreshGrid = () => {
    gridRef.current.refreshGrid();
  };


  const handleDeclineClick = (rowData) => {
    setSelectedDeclineRow(rowData?.original);
    onDeclineOpen();
  };

  const handleDeclineOnCLose = () => {
    setSelectedDeclineRow(null);
    onDeclineClose();
  };

  const handleApproveClick = (rowData) => {
    setSelectedApproveRow(rowData?.original);
    onOpen();
  };

  const handleApproveOnCLose = () => {
    setSelectedApproveRow(null);
    onClose();
  };

  const approveWallet = async () => {
    setLoading(true);
    try {
      const updatedData = {
        id: selectedApproveRow?._id,
        status: "approved",
      };
      const response = await callApiWithToken('POST', '/api/treasury/approveWallet', updatedData);
      if (response?.message) {
        toast({
          title: 'Success',
          description: response?.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
      }
    }
    catch (error) {
      console.log(error);
      toast({
        title: 'Error!',
        description: "Failed to Update the live rate",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
    }
    setLoading(false);
    handleApproveOnCLose();
  };


  const declineWallet = async () => {
    setLoading(true);
    try {
      const updatedData = {
        id: selectedDeclineRow?._id,
        status: "declined",
      };
      const response = await callApiWithToken('POST', '/api/treasury/approveWallet', updatedData);
      if (response?.message) {
        toast({
          title: 'Success',
          description: response?.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
      }
    }
    catch (error) {
      toast({
        title: 'Error!',
        description: "Failed to Update the live rate",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
    }
    setLoading(false);
    handleDeclineOnCLose();
  };

  const columns = [
    {
      headerName: "Wallet Name",
      width: "200",
      field: "walletName",
      getQuickFilterText: params => params.value.name,
    },
    {
      headerName: "Address",
      width: "400",
      field: "walletAddress",
      getQuickFilterText: params => params.value.name,
    },
    {
      headerName: "Type",
      width: "200",
      field: "contactType",
      getQuickFilterText: params => params.value.name,
      cellRenderer: ({ value }) => startCase(value) || "-",
    },
    {
      headerName: "Token",
      width: "200",
      field: "token",
      getQuickFilterText: params => params.value.name,
    },
    {
      headerName: "Network",
      width: "200",
      field: "network",
      getQuickFilterText: params => params.value.name,
    },
    {
      headerName: "Action",
      field: "",
      width: "200",
      cellRenderer: (params) => {
        const row = params.data;
        return (
          <>
            <Button
              style={{ marginRight: "15px" }}
              size="sm"
              colorScheme="red"
              onClick={() => {
                handleDeclineClick(row)
              }}
              isDisabled={row?.addedBy === userEmail}
            >
              Decline
            </Button>
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => {
                handleApproveClick(row);
              }}
              isDisabled={row?.addedBy === userEmail}
            >
              Approve
            </Button>
          </>

        );
      },
    }
  ];


  return !loading ? (
    <Box style={{ background: "white" }} heading="Live LiveRates">
      <div className="ag-theme-quartz" style={{ height: 600, width: '100%', marginTop: '20px' }}>
        <AgGridReact
          columnDefs={columns}
          defaultColDef={defaultColDef}
          ref={gridRef}
          rowModelType="serverSide"
          serverSideStoreType='partial'
          pagination={true}
          paginationPageSize={50}
          cacheBlockSize={50}
          paginationPageSizeSelector={[50, 100, 250, 500]}
          onGridReady={onGridReady}
          quickFilterText={quickFilterText}
          quickFilterParser={quickFilterParser}
          sideBar={false}
          suppressMenuHide={true}
          enableAdvancedFilter={true}
          suppressMultiRangeSelection={true}
          enableRangeSelection={true}
          rowGroupPanelShow={'always'}
          groupIncludeFooter={true}
          rowSelection={'multiple'}
        />
      </div>
      <Modal isOpen={isOpen} onClose={handleApproveOnCLose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Approve Wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to approve the wallet?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={handleApproveOnCLose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={approveWallet} isLoading={loading}
            >
              Approve
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeclineOpen} onClose={handleDeclineOnCLose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Decline Wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to decline the wallet?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={handleDeclineOnCLose}>
              Close
            </Button>
            <Button
              colorScheme="red"
              onClick={declineWallet} isLoading={loading}
            >
              Decline
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  ) : (
    <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
  );
}