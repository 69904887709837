import React, { useEffect, useState } from 'react';
import { Box, Badge, Center, Text } from "@chakra-ui/react";
import { callApiWithToken } from '../../../utils/utils';

const Errors = (props) => {
  const { orderId } = props || {};
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    fetchOrderData();
  }, []);

  const fetchOrderData = async () => {
    try {
      const response = (await callApiWithToken("GET", `/api/orders/getOrderDetails?orderId=${orderId}`)) || {};
      const { data = [] } = response;
      setOrderData(data);
    } catch (error) {
      console.error("Error while fetching order errors:", error);
    }
  };

  const renderErrorInfo = (label, value) => (
    <>
      <Text fontSize="sm" fontWeight="semibold">{label}</Text>
      <Badge colorScheme={'red'} maxWidth="100%" whiteSpace="normal" wordBreak="break-word" p="2">
        {value || `No ${label}`}
      </Badge>
    </>
  );

  return (
    <Center>
      <Box
        width={'100%'}
        maxH={'500px'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        bg={'gray.100'}
        boxShadow="lg"
        p={4}
        overflowY={'auto'}
      >
        {
          orderData?.error ? (
            <Box display="flex" flexDirection="column" gap="10px" ml="20px">
              {orderData?.type === 'buy' ? (
                <>
                  {renderErrorInfo('Error Code', orderData?.error?.externalCode)}
                  {renderErrorInfo('Error Message', orderData?.error?.externalMessage)}
                </>
              ) : (
                <>
                  {renderErrorInfo('Error Stack', orderData?.error?.stack)}
                  {renderErrorInfo('Error Message', orderData?.error?.message)}
                </>
              )}
            </Box>
          ) : (
            <Text mb={4} color={'gray'}>No error messages!</Text>
          )
        }
      </Box>
    </Center>
  );
};

export default Errors;
