import React, { useState, useRef, useMemo, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { startCase } from "lodash";
import moment from "moment";
import { Box, Button, Badge, Spinner, Stack, HStack } from "@chakra-ui/react";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.min.css";
import "ag-grid-community/styles/ag-theme-quartz.min.css";
import { utils, writeFileXLSX } from 'xlsx';
import { DownloadIcon } from "@chakra-ui/icons";

import { ReactComponent as MoneyMovement } from '../../icons/moneyMovement.svg';
import { callApiWithToken } from "../../utils/utils";
import { getCxRoles } from "../App/useToken";

export default function Treasury() {
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [api, setApi] = useState(null);
    const navigate = useNavigate();
    const quickFilterText = '';
    const gridRef = useRef();
    const roles = getCxRoles();

    const onGridReady = useCallback((params) => {
        gridRef.current = params.api;
        setApi(params);
        const datasource = {
            async getRows(params) {
                console.log(params.request)
                const { startRow, endRow, filterModel } = params.request;
                const page = Math.floor(startRow / (endRow - startRow));
                const limit = endRow - startRow;

                try {
                    const responseData = await callApiWithToken("POST", `/api/treasury/list`, { page, limit, filters: filterModel });
                    const { data, total } = responseData;

                    setTotalRows(total);

                    params.success({
                        rowData: data,
                        rowCount: total,
                    });
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching rows', error);
                    params.fail();
                } finally {
                    setLoading(false);
                }
            }
        };
        params.api.setServerSideDatasource(datasource);
    }, []);

    const defaultColDef = useMemo(() => ({
        filter: true,
        floatingFilter: true,
    }), []);

    const quickFilterParser = useCallback((quickFilter) => {
        let quickFilterParts = [];
        let lastSpaceIndex = -1;
        const isQuote = (index) => quickFilter[index] === '"';
        const getQuickFilterPart = (lastSpaceIndex, currentIndex) => {
            const startsWithQuote = isQuote(lastSpaceIndex + 1);
            const endsWithQuote = isQuote(currentIndex - 1);
            const startIndex =
                startsWithQuote && endsWithQuote
                    ? lastSpaceIndex + 2
                    : lastSpaceIndex + 1;
            const endIndex =
                startsWithQuote && endsWithQuote ? currentIndex - 1 : currentIndex;
            return quickFilter.slice(startIndex, endIndex);
        };
        for (let i = 0; i < quickFilter.length; i++) {
            const char = quickFilter[i];
            if (char === ' ') {
                if (!isQuote(lastSpaceIndex + 1) || isQuote(i - 1)) {
                    quickFilterParts.push(getQuickFilterPart(lastSpaceIndex, i));
                    lastSpaceIndex = i;
                }
            }
        }
        if (lastSpaceIndex !== quickFilter.length - 1) {
            quickFilterParts.push(
                getQuickFilterPart(lastSpaceIndex, quickFilter.length)
            );
        }
        return quickFilterParts;
    }, []);

    const columns = [
        {
            headerName: "Order Id",
            field: "orderId",
            width: 250,
            getQuickFilterText: params => params.value.name,
            cellRenderer: ({ value }) => (
                <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/treasury-order/${value}`}>
                    {value}
                </Link>
            )
        },
        {
            headerName: "Status",
            field: "status",
            width: 100,
            cellRenderer: ({ value }) => {
                const color = {
                    "completed": "green",
                    "initiated": "yellow",
                    "failed": "red"
                };
                return <Badge width={"100%"} textAlign={"center"} colorScheme={color[value]}> {startCase(value)} </Badge>;
            },
            getQuickFilterText: params => params.value.name,
        },
        {
            headerName: "Total Cost",
            field: "totalCost",
            width: 250,
            cellRenderer: ({ value }) => value || "0",
            getQuickFilterText: params => params.value.name,
        },
        {
            headerName: "Transfer Currency",
            field: "fromCcy",
            width: 250,
            cellRenderer: ({ value }) => value || "NA",
            getQuickFilterText: params => params.value.name,
        },
        {
            headerName: "Amount Sent",
            field: "fromAmount",
            width: 250,
            cellRenderer: ({ value }) => value || "NA",
            getQuickFilterText: params => params.value.name,
        },
        {
            headerName: "Output Currency",
            field: "toCcy",
            width: 250,
            cellRenderer: ({ value }) => value || "NA",
            getQuickFilterText: params => params.value.name,
        },
        {
            headerName: "Amount Received",
            field: "toAmount",
            width: 250,
            cellRenderer: ({ value }) => value || "NA",
            getQuickFilterText: params => params.value.name,
        }
    ];

    const downloadExcelReport = () => {

        if (!api || !api.columnApi) {
            console.error("Grid API is not ready.");
            return;
        }

        try {
            const rowData = [];
            api.api.forEachNode((node) => rowData.push(node.data));
            const allColumns = api.columnApi.getAllDisplayedColumns();
            if (!allColumns) {
                console.error("No columns found");
                return;
            }

            const visibleColumns = allColumns
                .filter(col => !col.getColDef().hide)
                .map(col => ({
                    headerName: col.getColDef().headerName || col.getColId(),
                    field: col.getColId()
                }));

            if (visibleColumns.length === 0) {
                console.error("No visible columns found");
                return;
            }

            const headers = visibleColumns.map(col => col.headerName);
            const data = rowData.map(row => visibleColumns.map(col => row[col.field] || ''));


            const ws = utils.aoa_to_sheet([headers, ...data]);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Treasury Orders");
            writeFileXLSX(wb, `treasury_orders_${moment().unix()}.xlsx`);
        } catch (error) {
            console.error("Error generating Excel report:", error);
        }
    };

    return !loading ? (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >
            <Box style={{ background: "white" }}>
                <HStack pb={5} justifyContent={"flex-end"}>
                    <Button
                        leftIcon={<MoneyMovement />}
                        colorScheme="blue"
                        style={{ margin: "0 8px" }}
                        size="sm"
                        isDisabled={!roles.includes("finance")}
                        onClick={() => navigate("/move-balance")}
                    >
                        Move Balance
                    </Button>
                    <Button
                        leftIcon={<DownloadIcon />}
                        onClick={() => downloadExcelReport()}
                        variant={"outline"}
                        colorScheme="blue"
                        style={{ margin: "0 8px" }}
                        size="sm"
                    >
                        Download Report
                    </Button>
                </HStack>
                <div className="ag-theme-quartz" style={{ height: 600, width: '100%', marginTop: '20px' }}>
                    <AgGridReact
                        columnDefs={columns}
                        defaultColDef={defaultColDef}
                        ref={gridRef}
                        rowModelType="serverSide"
                        serverSideStoreType='partial'
                        pagination={true}
                        paginationPageSize={50}
                        cacheBlockSize={50}
                        paginationPageSizeSelector={[50, 100, 200, 500]}
                        onGridReady={onGridReady}
                        quickFilterText={quickFilterText}
                        quickFilterParser={quickFilterParser}
                        sideBar={false}
                        suppressMenuHide={true}
                        enableAdvancedFilter={true}
                        suppressMultiRangeSelection={true}
                        enableRangeSelection={true}
                        rowGroupPanelShow={'always'}
                        groupIncludeFooter={true}
                        rowSelection={'multiple'}
                    />
                </div>
            </Box>
        </Stack>
    ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 700, width: '100%', marginTop: '20px' }}>
            <Spinner size="xl" />
        </div>
    );
}