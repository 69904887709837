import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Box,
  VStack,
  Text,
  Stack,
  Grid,
  Input,
  InputGroup,
  RadioGroup,
  Radio,
  chakra,
  useToast
} from '@chakra-ui/react';
import Select from "react-select";
import { uniqBy } from 'lodash';
import { useFormik } from 'formik';
import { getUserEmail } from '../App/useToken';

import { callApiWithToken } from "../../utils/utils";

const AddWallet = (props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const userEmail = getUserEmail();

  const [tokenOptions, setTokenOptions] = useState([]);
  const [networkOptions, setNetworkOptions] = useState([]);

  const handleOnCancel = () => {
    navigate("/move-balance")
  }

  const getTokenList = async () => {
    const response = await callApiWithToken("POST", `/api/treasury/tokenList`);
    const data = response.data;
    let list = data.map((wallet) => {
      return { value: wallet.formattedSymbol, label: wallet.symbol };
    });
    setTokenOptions(uniqBy(list, (obj) => obj.label));
  };
  const getNetworkList = async (value) => {
    const response = await callApiWithToken("POST", `/api/treasury/tokenList`);
    const data = response.data;
    let list = data.filter((wallet) => value.label === wallet.symbol).map((wallet) => {
      return { value: wallet.network, label: wallet.network };
    });
    setNetworkOptions(uniqBy(list, (obj) => obj.label));
  };

  useEffect(() => {
    getTokenList();
  }, []);

  const formik = useFormik({
    initialValues: {
      contactType: '' ,
      walletAddress: '',
      walletName: '',
      token: '',
      network: '',
      userEmail: userEmail
    },
    onSubmit: async (formValues) => {
      try {
        const response = await callApiWithToken("POST", "/api/treasury/addWallet", {
          contactType: formValues.contactType ,
          walletAddress: formValues.walletAddress,
          walletName: formValues.walletName,
          token: formValues.token.value,
          network: formValues.network.value,
          userEmail: userEmail
        });
        if(response?.error) {
          toast({
              title: 'Error!',
              description: response?.message || "Failed to fetch order details.",
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top-right'
          })
        } else {
          toast({
              title: 'Success',
              description: response?.message,
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top-right'
          })
          navigate("/move-balance")
        }
       
      } catch (err) {
        toast({
            title: 'Error!',
            description: response?.message || "Failed to fetch order details.",
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right'
        })
      }
      // formik.setValues({
      //   contactType: null,
      //   walletAddress: '',
      //   walletName: '',
      //   token: '',
      //   network: '',
      // });
    },
  });
  return (
    <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
    <Box minH={"92vh"} p={"16px 40px"} width="100%" bg={"#F2F2F2"}>
      <VStack spacing={4} alignItems="flex-start">
        <Box bg="white" w="1000px" mt={6} borderRadius={"9px"} p={"20px 40px"} alignSelf="center">
          <Text fontSize="22px" p={5} color="black" fontWeight="700px" lineHeight="28.64px">
            Add Wallet
          </Text>
          <Stack direction="column" justifyContent="space-between" flexGrow={1} p={5}>
            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} paddingTop="10px" gap={4}>
              <FormControl>
                <FormLabel>Contact Type</FormLabel>
                <RadioGroup
                  onChange={(value) => {
                    formik.setFieldValue("contactType", value);
                  }}
                  value={formik.values.contactType}
                >
                  <Stack direction="row">
                    <Radio value={"internal"} size='lg'>Internal</Radio>
                    <Radio value={"external"} size='lg'>External</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} paddingTop="10px" gap={4}>
              <FormControl>
                <FormLabel>Token</FormLabel>
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%', // Increase width here
                      fontSize: '16px',  // Adjust font size if necessary
                      height: '65px', // Adjust height if necessary
                      border: "1px solid #ddd",
                      borderRadius: "8px"
                    }),
                  }}
                  isSearchable={true}
                  placeholder='Select Token'
                  options={tokenOptions}
                  onChange={(value) => {
                    formik.setFieldValue("token", value);
                    getNetworkList(value);
                  }}
                  value={formik.values.token}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Network</FormLabel>
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%', // Increase width here
                      fontSize: '16px',  // Adjust font size if necessary
                      height: '65px', // Adjust height if necessary
                      border: "1px solid #ddd",
                      borderRadius: "8px"
                    }),
                  }}
                  isSearchable={true}
                  placeholder='Select Network'
                  options={networkOptions}
                  onChange={(value) => {
                    formik.setFieldValue("network", value);
                  }}
                  value={formik.values.network}
                />
              </FormControl>
            </Grid>
            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} paddingTop="10px" gap={4}>
              <FormControl>
                <FormLabel>Wallet Address</FormLabel>
                <Box
                  width="100%"
                  border="1px solid #ddd"
                  borderRadius="8px"
                  p="12px"
                  bg="white"
                  boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
                  display="flex"
                  alignItems="center"
                >
                  {/* The icon should float to the right */}
                  <InputGroup flex="1">
                    <Input
                      fontWeight="500"
                      fontSize="18px"
                      placeholder="Enter Wallet Address"
                      border="none"
                      value={formik.values.walletAddress}
                      onChange={formik.handleChange}
                      name="walletAddress"
                    />
                  </InputGroup>
                </Box>
              </FormControl>
              <FormControl>
                <FormLabel>Wallet Nickname</FormLabel>
                <Box
                  width="100%"
                  border="1px solid #ddd"
                  borderRadius="8px"
                  p="12px"
                  bg="white"
                  boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
                  display="flex"
                  alignItems="center"
                >
                  {/* The icon should float to the right */}
                  <InputGroup flex="1">
                    <Input
                      fontWeight="500"
                      fontSize="18px"
                      placeholder="Enter nickname"
                      border="none"
                      value={formik.values.walletName}
                      onChange={formik.handleChange}
                      name="walletName"
                    />
                  </InputGroup>
                </Box>
              </FormControl>
            </Grid>
            <Stack direction="row" alignSelf={'flex-end'} flexGrow={1} p={5} gap={5}>
              <Button width={'110px'} onClick={() => handleOnCancel()}>Cancel</Button>
              <Button colorScheme="blue" width={'110px'} onClick={formik.handleSubmit}>
                Confirm </Button>
            </Stack>
          </Stack>
        </Box>
      </VStack>
    </Box>
    </chakra.form>
  );
};

export default AddWallet;
