import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Box,
  VStack,
  Text,
  Stack,
  Grid,
  Input,
  InputGroup,
  InputRightAddon,
  chakra,
  useToast
} from '@chakra-ui/react';
import { FaSearch, FaAngleDown } from "react-icons/fa";
import Select from "react-select";
import { uniqBy } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { NavBarContext } from "../../context/NavBarContext";
import { callApiWithToken } from "../../utils/utils";
import { getOrgId } from '../App/useToken';

const MoveBalance = (props) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu visibility
  const [walletAddressOptions, setWalletAddressOptions] = useState([]);

  const exchangeOptions = [
    {label: "Huobi", value:"huobi" },
    {label: "Binance", value: "binance" },
    {label: "Kucoin", value: "kucoin" },
    {label: "Metamask", value: "metamask" },
  ]

  const orgId = getOrgId();

  const handleSelectOption = (option) => {
    setSelectedOption(option.label); 
    formik.setFieldValue("walletAddress", option.value);
    formik.setFieldValue("token", option.token);
    formik.setFieldValue("network", option.network);
    formik.setFieldValue("walletName", option.walletName);
    setIsMenuOpen(false); // Close the menu when an option is selected
  };

  const handleOnCancel = () => {
    navigate("/treasury-order")
  }

  const getApprovedWalletAddressList = async () => {
    const response = await callApiWithToken("POST", `/api/treasury/approvedWallet`);
    const data = response.data;
    let list = data.map((wallet) => {
        return { value: wallet.walletAddress, label: wallet.walletName, token: wallet.token, network: wallet.network, walletName: wallet.walletName };
    });
    setWalletAddressOptions(uniqBy(list, (obj) => obj.value));
};

useEffect(() => {
  getApprovedWalletAddressList();
}, []);

  const ToList = () => {
    const filteredOptions = walletAddressOptions.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
      <Box
        width="100%"
        border="1px solid #ddd"
        borderRadius="8px"
        p="12px"
        bg="white"
        boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
        mt="8px"
      >
      <Box
        display="flex"
        alignItems="center"
        border="1px solid #ddd"
        borderRadius="8px"
        p="8px"
        mb="16px"
      >
        <FaSearch style={{ color: "#3393f3", marginRight: "8px" }} />
          <Input
            width="100%"
            border={'none'}
            type='text'
            fontSize="16px"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term
            autoFocus
          />
      </Box>
        <Box maxHeight="150px" overflowY="auto" mb="16px">
          {filteredOptions.map((option) => (
            <Box
              key={option.id}
              display="flex"
              alignItems="center"
              p="8px"
              borderBottom="1px solid #f0f0f0"
              _hover={{ backgroundColor: "#f9f9f9" }}
              cursor="pointer"
              onClick={() => handleSelectOption(option)}
            >
              <span>{option.label}</span>
            </Box>
          ))}
        </Box>

        {/* Add Wallet */}
        <Box
          onClick={() => navigate("/add-wallet")}
          display="flex"
          justifyContent="center"
          color="#3393f3"
          fontWeight="bold"
          cursor="pointer"
          textDecoration="underline"
        >
          <span>Add Wallet</span> <span>+</span>
        </Box>
      </Box>
    );
  };

  const formik = useFormik({
    initialValues: {
        amount: '',
        fromPartner: '',
        walletAddress: '',
        walletName: '',
        token: '',
        network: '',
        orgId: orgId
    },
    onSubmit: async (formValues) => {
        try {
            const response = await callApiWithToken("POST", `/api/treasury/moveBalance`, {
              amount: formValues.amount,
              fromPartner: formValues.fromPartner.value,
              walletAddress: formValues.walletAddress,  
              token: formValues.token,
              network: formValues.network,
              orgId: orgId,
              walletName: formValues.walletName
            });

            if (response.status === 200) {
                toast({
                    title: 'Success!',
                    description: response?.message || "Order details fetched successfully.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                });
                navigate("/treasury-order");
            } else {
                toast({
                    title: 'Error!',
                    description: response?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        } catch (err) {
            toast({
                title: 'Error!',
                description:  "Failed to fetch order details.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
        }
    },
});

  return (
    <Box width="100%" bg="#F2F2F2">
      <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
        <Box minH={"92vh"} p={"16px 40px"} width="100%" bg={"#F2F2F2"}>
          <VStack spacing={4} alignItems="flex-start">
            <Box bg="white" w="1000px" mt={6} borderRadius={"9px"} p={"20px 40px"} alignSelf="center">
              <Text fontSize="22px" p={5} color="black" fontWeight="700px" lineHeight="28.64px">
                Move Balances
              </Text>
              <Stack direction="column" justifyContent="space-between" flexGrow={1} p={5}>
                <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} paddingTop="10px" gap={4}>
                  <FormControl>
                    <FormLabel>To</FormLabel>
                    <Box
                      width="100%"
                      border="1px solid #ddd"
                      borderRadius="8px"
                      p="12px"
                      bg="white"
                      boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
                      display="flex"
                      alignItems="center"
                    >
                      {/* The icon should float to the right */}
                      <InputGroup flex="1">
                        <Input
                          fontWeight="500"
                          fontSize="18px"
                          placeholder="Select"
                          border="none"
                          value={selectedOption || ''}
                          readOnly
                        />
                        <InputRightAddon
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          marginLeft="auto"
                          bg="transparent"
                          border="none"
                          paddingRight="10px"
                          cursor="pointer"
                          onClick={() => setIsMenuOpen(!isMenuOpen)} // Toggle the menu
                        >
                          <FaAngleDown />
                        </InputRightAddon>
                      </InputGroup>
                    </Box>
                    {isMenuOpen && <ToList />} {/* Conditionally render the menu */}
                  </FormControl>
                  <FormControl>
                    <FormLabel>From</FormLabel>
                    <Select 
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%', // Increase width here
                          fontSize: '16px',  // Adjust font size if necessary
                          height: '65px', // Adjust height if necessary
                          border:"1px solid #ddd",
                          borderRadius:"8px"
                        }),
                      }}
                      id="walletName"
                      type="text"
                      isSearchable={true}
                      placeholder='Select'
                      options={exchangeOptions}
                      onChange={(value) => formik.setFieldValue("fromPartner", value)}
                      value={formik.values.fromPartner}
                    />
                  </FormControl>
                </Grid>
                <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} paddingTop="10px" gap={4}>
                  <FormControl>
                    <FormLabel>Using</FormLabel>
                    <Box
                      width="100%"
                      border="1px solid #ddd"
                      borderRadius="8px"
                      p="12px"
                      bg="white"
                      boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
                      display="flex"
                      alignItems="center"
                    >
                      {/* The icon should float to the right */}
                      <InputGroup flex="1">
                        <Input
                          fontWeight="500"
                          fontSize="18px"
                          placeholder="select"
                          border="none"
                          // value={selectedOption || ''}
                        />
                      </InputGroup>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} paddingTop="10px" gap={4}>
                  <FormControl>
                    <FormLabel>Token</FormLabel>
                    <Box
                      width="100%"
                      border="1px solid #ddd"
                      borderRadius="8px"
                      p="12px"
                      bg="white"
                      boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
                      display="flex"
                      alignItems="center"
                    >
                      {/* The icon should float to the right */}
                      <InputGroup flex="1">
                        <Input
                          fontWeight="500"
                          fontSize="18px"
                          placeholder="select"
                          border="none"
                          value={formik.values.token}
                          readOnly
                        />
                      </InputGroup>
                    </Box>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Network</FormLabel>
                    <Box
                      width="100%"
                      border="1px solid #ddd"
                      borderRadius="8px"
                      p="12px"
                      bg="white"
                      boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
                      display="flex"
                      alignItems="center"
                    >
                      {/* The icon should float to the right */}
                      <InputGroup flex="1">
                        <Input
                          fontWeight="500"
                          fontSize="18px"
                          placeholder="select"
                          border="none"
                          value={formik.values.network}
                          readOnly
                        />
                      </InputGroup>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} paddingTop="10px" gap={4}>
                  <FormControl>
                    <FormLabel>Amount</FormLabel>
                    <Box
                      width="100%"
                      border="1px solid #ddd"
                      borderRadius="8px"
                      p="12px"
                      bg="white"
                      boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
                      display="flex"
                      alignItems="center"
                    >
                      {/* The icon should float to the right */}
                      <InputGroup flex="1">
                        <Input
                        name="amount"
                          fontWeight="500"
                          fontSize="18px"
                          placeholder="Enter amount to be trasnferred"
                          border="none"
                          type="number"
                          value={formik.values.amount}
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                    </Box>
                  </FormControl>
                </Grid>
                <Stack direction="row"  alignSelf={'flex-end'} flexGrow={1} p={5} gap={5}>
                  <Button width={'110px'} onClick={() => handleOnCancel() }>Cancel</Button>
                  <Button colorScheme="blue" width={'110px'} onClick={formik.handleSubmit} >
                    Confirm </Button>
                </Stack>
              </Stack>
            </Box>
          </VStack>
        </Box>
      </chakra.form>
    </Box>
  );
};

export default MoveBalance;
