import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { useParams, Link } from "react-router-dom";
import moment from "moment-timezone";
import { Badge, useDisclosure, Button, useToast } from "@chakra-ui/react";
import { startCase, uniqBy, isEmpty, get, round } from "lodash";
import { Box } from "@chakra-ui/react";
import { BiRefresh, BiReset } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { BsCartPlus } from "react-icons/bs";
import { DownloadIcon } from "@chakra-ui/icons";

import SplitView from "../../utils/SplitView";
import { NavBarContext } from "../../context/NavBarContext";
import { TimezoneContext } from "../../context/TimezoneContext";
import OrderSpliViewContainer from "./OrderSpliViewContainer";
import OrderFilter from "./helper/OrderFilter";
import { statusMap, colorMap } from "../../utils/config";
import { callApiWithToken } from "../../utils/utils";
import { getCxRoles, getOrgRoles, getOrgName } from "../App/useToken";
import SelectedConfirmModal from "./helper/SelectedConfirmModal";
import SellConfirmationModal from "./helper/SellConfirmationModal";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import { getCountryData } from "../../utils/getCountries";

export default function SellOrders() {
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenSelectedModal, onOpen: onOpenSelectedModal, onClose: onCloseSelectedModal } = useDisclosure();
    const { isOpen: isOpenConfirmationModal, onOpen: onOpenConfirmationModal, onClose: onCloseConfirmationModal } = useDisclosure();
    const [countryOptions, setCountryOptions] = useState([]);
    const [fiatCurrency, setFiatCurrency] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [inputData, setInputData] = useState({});
    const defaultDate = { startDate: moment().subtract(2, "months"), endDate: moment().format("YYYY-MM-DD 23:59:59") };
    const [filters, setFilters] = useState({ dateRange: defaultDate });
    const [dateRange, setDateRange] = useState(defaultDate);
    const [selectedRows, setSelectedRows] = useState([]);
    const [summaryDetails, setSummaryDetails] = useState([]);
    const [selectedModalMessage, setSelectedModalMessage] = useState("");

    const toast = useToast();
    const orgName = getOrgName();

    const roles = getOrgRoles();
    const { selectedOrg } = useContext(NavBarContext);
    const { timezone } = useContext(TimezoneContext);
    const gridRef = useRef();

    const showColumn = roles.includes("transfi_admin");

    useEffect(() => {
        getCountryList();
        getPaymentList();
    }, []);

    useEffect(() => {
        refreshGrid();
    }, [timezone]);

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };

    const getCountryList = async () => {
        const { countryList, fiatList } = await getCountryData();
        setCountryOptions(countryList);
        setFiatCurrency(fiatList);
    };

    const getPaymentList = async () => {
        const response = await callApiWithToken("GET", `/api/orders/getPaymentMethods`);
        const data = response.data;
        let list = data.map((country) => {
            return { value: country.name, label: country.name };
        });
        setPaymentOptions(uniqBy(list, (obj) => obj.value));
    };

    const numberFormatter = (value) => {
        const number = round(value, 2).toFixed(2)
        const result = parseFloat(number).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return result === "NaN" ? "0.00" : result;
    };

    const selectionCallback = React.useCallback((ids) => setSelectedRows(ids), []);

    const createBatch = () => {
        if (selectedRows.length) {
            setSelectedModalMessage("Settlement will be created on selected transactions on current screen.");
        } else {
            setSelectedModalMessage("Settlements will be created on filtered date and currency");
        }
        onOpenSelectedModal();
    }

    const summaryView = async () => {
        setLoading(true);
        const selectedDetails = selectedRows.map((data) => data.orderId);
        const response = await callApiWithToken("POST", "/api/settlement/revShareBatch/overview", {
            data: selectedDetails,
            filters: { impersonatedOrg: selectedOrg, filters, type: 'sell' }
        });
        setSummaryDetails(response?.message);
        onOpenConfirmationModal();
        setError(response?.error);
        setLoading(false);
    }

    const handleSettlement = () => {
        summaryView();
    }

    const downloadReport = () => {
        gridRef.current.downloadReport();
    };

    const binanceOrgs = ["binance", "binance_qa", "binance_prod", "binance_africa", "openpayd"]

    const columns = [
        {
            Header: "Order Id",
            accessor: "orderId",
            width: "200",
            Cell: ({ value, row }) => {
                return (
                    <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/sell-orders/${value}`}>
                        {value}
                    </Link>
                );
            },
            zIndex: 1,
            position: 'sticky',
            textAlign: 'center',
        },
        {
            Header: "Customer Order Id",
            accessor: "customer.orderId",
            show: roles.includes("transfi_admin") ? true : false,
            width: "150",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Customer",
            accessor: "customer.formattedName",
            show: roles.includes("transfi_admin") ? true : false,
            width: "100",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Email",
            accessor: "email",
            width: "230",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Status",
            accessor: "status",
            width: "130",
            Cell: ({ row }) => {
                const { status, type } = row?.original;
                const newStatus = statusMap[type][status] || status;
                const color = colorMap[type][status] || "blue";
                return <Badge colorScheme={color}> {newStatus} </Badge>;
            },
        },
        {
            Header: "User Country",
            width: "100",
            Cell: ({ row }) => {
                return row?.original?.userCountry || "N/A";
            }
        },
        {
            Header: "Date Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.createdAt} format={"MM/DD/YYYY"} />
            },
            color: "info",
        },
        {
            Header: "Time Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original?.createdAt} format={"LT"} />
            },
            color: "info",
        },
        {
            Header: "Date Completed",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "fund_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.fundSettledAt} format={"MM/DD/YYYY"} />
                    } else {
                        return "N/A"
                    }
                } else {
                    return "N/A";
                }
            },
            color: "info",
        },
        {
            Header: "Time Completed",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "fund_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.fundSettledAt} format={"LT"} />
                    }
                    else {
                        return "N/A"
                    }
                }
                else {
                    return "N/A";
                }
            },
            color: "info",
        },
        {
            Header: "Crypto Payment Partner",
            accessor: "crypto.partner",
            width: "170",
            Cell: ({ value }) => startCase(value) || "NA",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "Crypto Payment Order ID",
            accessor: "crypto.orderId",
            width: "170",
            Cell: ({ value }) => value || "NA",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "Crypto Currency",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.currency)) {
                    return row?.original?.crypto?.currency;
                } else {
                    return row?.original?.cryptoTicker;
                }
            },
            color: "warning",
        },
        {
            Header: "Crypto Sold By The User",
            width: "150",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.orderAmount)) {
                    return numberFormatter(row?.original?.crypto?.orderAmount);
                } else {
                    return numberFormatter(row?.original?.cryptoAmount);
                }
            },
            color: "warning",
        },
        {
            Header: "Crypto Amount Received By TransFi",
            width: "190",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.orderAmount)) {
                    return numberFormatter(row?.original?.crypto?.orderAmount);
                } else {
                    return numberFormatter(row?.original?.cryptoAmount);
                }
            },
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
        },

        {
            Header: "Crypto Conversion Rate USDC / Crypto",
            width: "190",
            Cell: ({ row }) => {
                if (row?.original?.crypto?.conversionRate) {
                    return numberFormatter(row?.original?.crypto?.conversionRate);
                } else {
                    return row?.original?.cryptoUnitPrice ? numberFormatter(row?.original?.cryptoUnitPrice) : "0.00";
                }
            },
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "USDC / USDT Purchased (Crypto Account)",
            accessor: "crypto.conversionAmount",
            width: "230",
            Cell: ({ value }) => numberFormatter(value) || "0.00",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "USDC / USDT Purchased (Recalculated)",
            width: "230",
            Cell: ({ row }) => {
                const orderAmount = !isEmpty(row?.original?.crypto?.orderAmount) ? Number(row?.original?.crypto?.orderAmount || 0) : Number(row?.original?.cryptoAmount || 0);
                const conversionRate = !isEmpty(row?.original?.crypto?.conversionRate) ? Number(row?.original?.crypto?.conversionRate || 0) : Number(row?.original?.cryptoUnitPrice || 0);
                return numberFormatter(orderAmount * conversionRate);
            },
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
        },
        {
            Header: "Txn Hash",
            width: "200",
            accessor: "metaData.txnIdForCryptoReceived[0]",
            color: "warning",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Crypto Wallet Address",
            width: "400",
            color: "warning",
            Cell: ({ row }) => {
                if (row?.original?.type === "sell") {
                    if (!isEmpty(row?.original?.metaData?.sendersWalletAddress)) {
                        return row?.original?.metaData?.sendersWalletAddress[0];
                    } else {
                        return "N/A";
                    }
                }
                else {
                    if (!isEmpty(row?.original?.walletAddress)) {
                        return row?.original?.walletAddress;
                    } else return "N/A";
                }

            }
        },
        {
            Header: "Fiat Payment Partner",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.partner)) {
                    return row?.original?.fiat?.partner
                } else {
                    return row?.original?.paymentGateway
                }
            }
        },
        {
            Header: "Fiat Partner Order ID",
            accessor: "fiat.orderId",
            width: "200",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
            Cell: ({ value }) => value || "-",
        },
        {
            Header: "Fiat Payment Method",
            width: "150",
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.paymentName)) {
                    return row?.original?.fiat?.paymentName;
                } else {

                    return row?.original?.paymentName;
                }
            }
        },
        {
            Header: "Account Number",
            accessor: "paymentAccountNumber",
            width: "150",
            color: "info",
            Cell: ({ value }) => {
                return value || "N/A"
            },
            show: (roles.includes("transfi_admin") || ["payout_qa", "parallax"].includes(orgName)) ? true : false,
        },
        {
            Header: "Fiat Currency",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.currency)) {
                    return row?.original?.fiat?.currency;
                } else {

                    return row?.original?.fiatTicker;
                }
            }
        },
        {
            Header: "Bank Reference Id",
            accessor: "fiat.bankTxnIds",
            width: "150",
            color: "info",
            Cell: ({ value }) => {
                return value?.join(", ") || "NA"
            },
        },
        {
            Header: "Conversion Rate to USD",
            width: "150",
            color: "info",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    return numberFormatter(1 / row?.original?.fiat?.conversionRate);
                }

            },
        },
        {
            Header: "Conversion Rate to EUR",
            width: "150",
            color: "info",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRateInEur)) {
                    return "0.00";
                } else {
                    return numberFormatter(1 / row?.original?.fiat?.conversionRateInEur);
                }

            },
        },
        {
            Header: "Gross Txn Amount On Fiat",
            width: "150",
            color: "info",
            Cell: ({ row }) => {
                const feeAbstraction = get(row?.original, "fiat.feeAbstraction", false);
                if (feeAbstraction && ["payout_qa", "parallax"].includes(orgName)) {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;

                    return numberFormatter(Number(fiatAmount || 0))
                } else {
                    if (!isEmpty(row?.original?.fiat?.orderAmount)) {
                        const amount = Number(row?.original?.fiat?.orderAmount)
                        const processingFee = Number(row?.original?.fees?.processingFee)
                        const grossAmount = amount + processingFee
                        return numberFormatter(grossAmount);
                    } else {
                        const amount = Number(row?.original?.fiatAmount)
                        const processingFee = Number(row?.original?.fees?.processingFee)
                        const grossAmount = amount + processingFee
                        return numberFormatter(grossAmount);
                    }
                }
            }
        },
        {
            Header: "Processing fees %",
            // accessor: "fiat.processingFees",
            width: "120",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                const processingRate = get(row?.original, "fiat.processingRate", 0)
                const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                if (row?.original?.fiat?.processingFeeMode === 'hybrid') {
                    return processingRate;
                }
                if (processingRate > 0) {
                    return numberFormatter(processingRate) + "%";
                }
                if (tfFee >= 0 && cxFee >= 0 && isEmpty(processingRate)) {
                    const totalFees = tfFee + cxFee;
                    return numberFormatter((totalFees / fiatAmount) * 100) + "%";
                }
                else {
                    return "N/A"
                }

            },
            color: "info",
        },
        {
            Header: "Processing fees amt.",
            width: "180",
            show: !["payout_qa", "parallax"].includes(orgName) ? true : false,
            Cell: ({ row }) => {
                if (row?.original?.fiat?.processingFeeMode === 'hybrid') {
                    const processingFee = Number(row?.original?.fiat?.processingFee).toFixed(2)
                    return numberFormatter(processingFee)
                }
                if (binanceOrgs.includes(row?.original?.customer?.formattedName) && row?.original?.fiatTicker === "IDR" && row?.original?.fiat?.processingRate === "N/A") {
                    return numberFormatter(row?.original?.fiat.processingFee)
                } else {
                    const cryptoAmount = row?.original?.crypto?.orderAmount ? Number(row?.original?.crypto?.orderAmount || 0) : Number(row?.original?.cryptoAmount || 0);
                    const conversionRate = Number(row?.original?.crypto?.conversionRate || 0);
                    const processingRate = Number(row?.original?.fiat?.processingRate || 0);
                    const fiatConversionRate = Number(row?.original?.fiat?.conversionRate || 0);
                    const processingRateAmount = processingRate / 100;
                    return numberFormatter(Number((cryptoAmount * conversionRate * processingRateAmount) / fiatConversionRate || 0));
                }

            },
            color: "info",
        },
        {
            Header: "Discount %",
            width: "180",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                const discountRate = Number(get(row.original, "fiat.discountRate", 0))
                return numberFormatter(discountRate)

            },
            color: "warning",
        },
        {
            Header: "Discount amt.",
            width: "180",
            Cell: ({ row }) => {
                const discountRate = Number(get(row.original, "fiat.discount", 0))
                return numberFormatter(discountRate)

            },
            color: "warning",
        },
        {
            Header: "Amt. paid from sub account",
            width: "150",
            Cell: ({ row }) => {
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                const buyRate = Number(row?.original?.fiat?.partnerFee || 0)

                const amountPaid = fiatAmount + buyRate

                return numberFormatter(Number(amountPaid || 0));
            },
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
        },
        {
            Header: "Fiat Delivered To User",
            width: "150",
            color: "info",
            Cell: ({ row }) => {
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                return numberFormatter(Number(fiatAmount || 0))
            }
        },

        {
            Header: "Payment partner fees",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
            Cell: ({ row }) => {
                if (row?.original?.fiat?.partnerFee) {
                    if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                        return "0.00";
                    } else {
                        return numberFormatter(row?.original?.fiat?.partnerFee);
                    }
                } else {
                    return "0.00";
                }

            }
        },
        {
            Header: "Transfi Gross Fee",
            width: "150",
            color: "info",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.tfFee) || row?.original?.fiat?.tfFee === "NaN") {
                    return "0.00";
                } else {
                    return numberFormatter(row?.original?.fiat?.tfFee);
                }
            },
        },
        {
            Header: "Customer Revenue Share",
            width: "150",
            color: "info",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.cxFee) || row?.original?.fiat?.cxFee === "NaN") {
                    return "0.00";
                } else {
                    return numberFormatter(row?.original?.fiat?.cxFee);
                }

            },
        },
        // {
        //     Header: "Transaction mointioring fees (Sumsub)",
        //     width: "230",
        //     accessor: "fiat.kytFee",
        //     color: "info",
        //     Cell: ({ value }) => value || "0.00",
        //     show: roles.includes("transfi_admin") ? true : false,
        // },
        {
            Header: "GTV USD",
            width: "150",
            color: "info",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const conversionRate = Number(row?.original?.fiat?.conversionRate || 0);
                    return numberFormatter(fiatAmount * conversionRate);
                }
            },
        },
        {
            Header: "Fiat Delivered To The User(USD)",
            width: "180",
            color: "info",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const conversionRate = Number(row?.original?.fiat?.conversionRate);
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    let processingFee;
                    if (binanceOrgs.includes(row?.original?.customer?.formattedName) && row?.original?.fiatTicker === "IDR" && row?.original?.fiat?.processingRate === "N/A") {
                        processingFee = row?.original?.fiat.processingFee;
                    } else {
                        const cryptoAmount = row?.original?.crypto?.orderAmount ? Number(row?.original?.crypto?.orderAmount || 0) : Number(row?.original?.cryptoAmount || 0);
                        const conversionRate = Number(row?.original?.crypto?.conversionRate || 0);
                        const processingRate = Number(row?.original?.fiat?.processingRate || 0);
                        const fiatConversionRate = Number(row?.original?.fiat?.conversionRate || 0);
                        const processingRateAmount = processingRate / 100;
                        processingFee = (cryptoAmount * conversionRate * processingRateAmount) / fiatConversionRate;
                    }
                    const fiatDelivered = Number(fiatAmount - processingFee);
                    return numberFormatter(Number(fiatDelivered * conversionRate || 0))
                }
            }
        },
        {
            Header: "Processing fees amt. (USD)",
            width: "180",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    if (binanceOrgs.includes(row?.original?.customer?.formattedName) && row?.original?.fiatTicker === "IDR" && row?.original?.fiat?.processingRate === "N/A") {
                        const processingFee = Number(row?.original?.fiat.processingFee || 0);
                        const conversionRate = Number(row?.original?.fiat?.conversionRate || 0);
                        return numberFormatter(processingFee * conversionRate)
                    } else {
                        const cryptoAmount = row?.original?.crypto?.orderAmount ? Number(row?.original?.crypto?.orderAmount || 0) : Number(row?.original?.cryptoAmount || 0);
                        const conversionRate = Number(row?.original?.crypto?.conversionRate || 0);
                        const processingRate = Number(row?.original?.fiat?.processingRate || 0);
                        const processingRateAmount = Number(processingRate / 100 || 0);
                        return numberFormatter(cryptoAmount * conversionRate * processingRateAmount);
                    }
                }
            },
            color: "info",
        },
        {
            Header: "Payment partner fees (USD)",
            width: "170",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate) && row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                    return "0.00";
                } else {
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    const partnerFee = Number(row?.original?.fiat?.partnerFee || 0);
                    return numberFormatter(conversionRate * partnerFee);
                }
            }
        },
        {
            Header: "Customer Revenue Share (USD)",
            width: "200",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate || row?.original?.fiat?.cxFee) || row?.original?.fiat?.cxFee === "NaN") {
                    return "0.00";
                } else {
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    const customerFee = Number(row?.original?.fiat?.cxFee || 0);
                    return numberFormatter(conversionRate * customerFee);
                }
            }
        },
        {
            Header: "TransFi Fees (USD)",
            width: "170",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
            Cell: ({ row }) => {
                let processingFee;
                const conversionRate = Number(row?.original?.crypto?.conversionRate || 0);
                const fiatConversionRate = Number(row?.original?.fiat?.conversionRate || 0)
                const customerFee = Number(row?.original?.fiat?.cxFee || 0);
                const partnerFee = Number(row?.original?.fiat?.partnerFee);
                if (binanceOrgs.includes(row?.original?.customer?.formattedName) && row?.original?.fiatTicker === "IDR" && row?.original?.fiat?.processingRate === "N/A") {
                    processingFee = row?.original?.fiat.processingFee
                } else {
                    const cryptoAmount = row?.original?.crypto?.orderAmount ? Number(row?.original?.crypto?.orderAmount || 0) : Number(row?.original?.cryptoAmount || 0);
                    const processingRate = Number(row?.original?.fiat?.processingRate || 0);
                    const processingRateAmount = processingRate / 100;
                    processingFee = (cryptoAmount * conversionRate * processingRateAmount) / fiatConversionRate;
                }
                const customerFeeUsd = Number(round(customerFee * fiatConversionRate, 2) || 0);
                const partnerFeeUsd = Number(round(partnerFee * fiatConversionRate, 2) || 0);
                const processingFeeUsd = Number(processingFee * fiatConversionRate || 0);

                return numberFormatter(processingFeeUsd - partnerFeeUsd - customerFeeUsd);
            }
        },
        {
            Header: "Api Key",
            accessor: "apiKey",
            width: "150",
            color: "info",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Settlement Batch ID",
            accessor: "batchId",
            width: "30",
            Cell: ({ value }) => {
                return (
                    <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/cx-revshare-settlements/batch/${value}`}>
                        {value}
                    </Link> || "NA"
                );
            },
        },
        {
            Header: "User Device",
            width: "150",
            color: "info",
            Cell: ({ row }) => {
                const userAgent = row?.original?.deviceDetails?.userAgent?.toLowerCase();
                if (!userAgent) {
                    return "Others";
                }
                if (userAgent.includes('ipad')) {
                    return 'iPad';
                } else if (userAgent.includes('android')) {
                    if (userAgent.includes('mobile')) {
                        return 'Android Phone';
                    } else {
                        return 'Android Tablet';
                    }
                } else if (userAgent.includes('iphone')) {
                    return 'iPhone';
                } else if (userAgent.includes('windows')) {
                    return 'Windows';
                } else if (userAgent.includes('macintosh') || userAgent.includes('mac os x')) {
                    return 'Macbook';
                } else {
                    return 'Others';
                }
            },
        },
        // {
        //     Header: "Sub Account Delivery Date",
        //     width: "150",
        //     color: "info",
        //     // Cell: ({ row }) => {
        //     //     if (isEmpty(row?.original?.fiat?.cxFee) || row?.original?.fiat?.cxFee === "NaN") {
        //     //         return "0.00";
        //     //     } else {
        //     //         return row?.original?.fiat?.cxFee;
        //     //     }

        //     // },
        // },
    ];

    const condensedColumns = {
        Header: "Order Id",
        accessor: "orderId",
        Cell: ({ row, value }) => {
            const { status, userId, type } = row || {};
            const newStatus = statusMap[type][status] || status;
            const color = colorMap[type][status] || "blue";
            return (
                <>
                    <div>
                        <span color="#4ACDED">{value}</span>
                        <Badge style={{ marginLeft: "20px" }} colorScheme={color}>
                            {" "}
                            {startCase(newStatus)}{" "}
                        </Badge>
                    </div>
                    <div style={{ fontSize: "13px" }}>{userId}</div>
                </>
            );
        },
    };

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters({ dateRange: defaultDate });
        setFormValues({});
        setInputData({});
    };

    return !loading ? (
        <Box style={{ background: "white" }} heading="Orders">
            {orderId ? (
                <></>
            ) : (
                <div style={{ marginTop: "2%" }}>
                    <div style={{ float: "right" }}>
                        <Button
                            leftIcon={<DownloadIcon />}
                            onClick={() => downloadReport()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Download Report
                        </Button>
                        {showColumn ? <Button
                            leftIcon={<BsCartPlus style={{ fontSize: "20px" }} />}
                            onClick={() => createBatch()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Create Settlement
                        </Button> : <></>}
                        <Button
                            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                            onClick={() => handleOnReset()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Reset
                        </Button>
                        <Button
                            leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                            onClick={() => refreshGrid()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 0px" }}
                            size="sm"
                        >
                            Refresh
                        </Button>
                        <Button
                            leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                            onClick={onOpen}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Filter
                        </Button>
                    </div>
                    <br />
                </div>
            )}
            <SplitView
                ref={gridRef}
                columns={columns}
                condensed={orderId}
                condensedColumns={condensedColumns}
                dataUrl={"/api/orders/list"}
                condensedUrl={"/sell-orders"}
                filters={{ impersonatedOrg: selectedOrg, filters, type: "sell" }}
                selectRow={showColumn}
                onChangeSelectedRow={selectionCallback}
                initialState={{ selectedRowIds: selectedRows }}
                downloadReportUrl={"/api/report"}
                collection={"orders"}
                database={"transfi-nucleus"}
                gridFilters={{ "type": "sell", filters, impersonatedOrg: selectedOrg }}
                headerDetails={{ types: { "createdAt": "date" } }}
                gridName={"Sell Orders"}
            >
                <OrderSpliViewContainer orderId={orderId} />
            </SplitView>

            <OrderFilter
                onClose={onClose}
                isOpen={isOpen}
                countryOptions={countryOptions}
                fiatCurrency={fiatCurrency}
                paymentOptions={paymentOptions}
                formValues={formValues}
                setFormValues={setFormValues}
                filters={filters}
                setFilters={setFilters}
                dateRange={dateRange}
                setDateRange={setDateRange}
                inputData={inputData}
                setInputData={setInputData}
                handleOnReset={handleOnReset}
                type={"sell"}
            ></OrderFilter>

            <SelectedConfirmModal
                isOpen={isOpenSelectedModal}
                onOpen={onOpenSelectedModal}
                onClose={onCloseSelectedModal}
                message={selectedModalMessage}
                handleSettlement={handleSettlement}
                loading={loading}
            />
            <SellConfirmationModal
                isLoading={loading}
                isOpen={isOpenConfirmationModal}
                onOpen={onOpenConfirmationModal}
                onClose={onCloseConfirmationModal}
                selectedRows={selectedRows}
                refreshGrid={refreshGrid}
                summaryDetails={summaryDetails}
                error={error}
                filters={filters}
            />

        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}
